<template>
    <div class="header hm">
        <div light app class="h-bar">
            <v-app-bar-nav-icon class="h__nav-icon" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <img class="h__logo" src="../assets/seaflealogo.png">
        </div>

        <v-navigation-drawer v-model="drawer" temporary app floating class="h__nav">
            <v-list nav dense>
                <v-list-item-group>
                    <div v-for="(tab, index) in tabs" :key="index" @click="GoTo(tab.element)" class="hm__nav-item">
                        <v-list-item-title class="h__nav-item">{{ tab.name }}</v-list-item-title>
                    </div>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <!-- <v-navigation-drawer v-model="drawer" temporary app floating class="h__nav">
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item v-for="(tab, index) in tabs" :key="index" @click="GoTo(tab.element)">
                        <v-list-item-title class="h__nav-item">{{ tab.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer> -->
    </div>
</template>

<script>
export default {
    name: 'HeaderMobile',

    data: () => ({
        tabs: [
            { 'name': 'Home', 'element': 'la' },
            { 'name': 'Who We Are', 'element': 'au' },
            { 'name': 'The Charter', 'element': 'tc' },
            { 'name': 'Meet The Crew', 'element': 'tcr' },
            { 'name': 'Boat', 'element': 'b' },
            { 'name': 'Trip Rates', 'element': 'r' },
            { 'name': 'Guest Info', 'element': 'gi' },
            { 'name': 'Trusted Brands', 'element': 'br' },
            { 'name': 'Contact', 'element': 'c' },
        ],
        drawer: false,
    }),
    methods: {
        GoTo(elementName) {
            const yOffset = 0;
            const element = document.getElementsByClassName(elementName)[0];
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            this.drawer = false;
        }
    }
}
</script>

<style scoped>
.hm {
    z-index: 10000;
    opacity: 0.8;
}

.h__nav {
    display: none;
    opacity: 80%;
    height: 100%;
}

.h__nav-icon {
    padding-left: 52%;
}

.h__tabs {
    display: none;
}

@media (min-width:1000px) {
    .hm{
        display: none;
    }
}

@media (max-width:1000px) {
    .h__nav {
        display: block;
    }

    .h-bar {
        height: 40px !important;
        width: 100vw;
        position: fixed;
        background-color: lightgray;
        border-bottom: 0.1px lightgray solid;
    }

    .h__logo {
        display: none;
    }
    .h__button{
        display: none;
    }
    .hm__nav-item{
        padding: 10px;
        -webkit-tap-highlight-color: darkgray;
    }
    .hm__nav-item:focus, .hm__nav-item:active, .hm__nav-item::selection{
        background-color: darkgray;
    }
}
</style>
